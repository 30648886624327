import {
  TDisabled,
  TFacility,
  TFeature,
  TFrequencySelectOption,
  TPropertyType,
  TSupportedLanguages,
  TUnitType,
} from '../types/app';
import { TTenant } from '../types/app/tenant';
import {
  TSelectInvoiceFrequency,
  TSelectInvoiceStatus,
} from '../types/app/invoices';
import {
  TSelectContractType,
  TSelectPropertiesStatus,
} from 'types/app/reports';

export const APP_NAME = 'RETM';
export const DEFAULT_LANGUAGE: TSupportedLanguages = 'en';
export const TIME_TO_RESEND_EMAIL = 60;
export const defaultMapCenter: [number, number] = [24.774265, 46.738586];
export const supportedCountries: string[] = ['sa', 'ua', 'ca'];
export const supportedLanguages: TSupportedLanguages[] = ['en', 'ar'];
export const supportedPropertiesTypes: string[] = [
  'apartment',
  'building',
  'villa',
  'land',
  'showroom',
];

export const countries = [
  {
    titleEn: 'Saudi Arabia',
    titleAr: 'المملكة العربية السعودية',
    code: 'SA',
  },
];

export const propertyTypes: TUnitType[] = [
  {
    titleEn: 'Apartment',
    titleAr: 'شقة',
    type: 'apartment',
  },
  {
    titleEn: 'Building',
    titleAr: 'مبنى',
    type: 'building',
  },
  {
    titleEn: 'Villa',
    titleAr: 'فيلا',
    type: 'villa',
  },
  {
    titleEn: 'Land',
    titleAr: 'أرض',
    type: 'land',
  },
  {
    titleEn: 'Showroom',
    titleAr: 'معرض',
    type: 'showroom',
  },
];

export const areas = [
  {
    titleAr: '< 250㎡',
    titleEn: '< 250㎡',
    areaFrom: '',
    areaTo: '249',
    key: 'to250',
  },
  {
    titleAr: '250㎡ - 500㎡',
    titleEn: '250㎡ - 500㎡',
    areaFrom: '250',
    areaTo: '499',
    key: 'to500',
  },
  {
    titleAr: '500㎡ - 750㎡',
    titleEn: '500㎡ - 750㎡',
    areaFrom: '500',
    areaTo: '749',
    key: 'to750',
  },
  {
    titleAr: '750㎡-1000㎡',
    titleEn: '750㎡-1000㎡',
    areaFrom: '750',
    areaTo: '999',
    key: 'to1000',
  },
  {
    titleAr: '> 1000㎡',
    titleEn: '> 1000㎡',
    areaFrom: '1000',
    areaTo: '',
    key: 'from1000',
  },
];

export const inviceStatuses: TSelectInvoiceStatus[] = [
  { labelAr: 'ريثما', labelEn: 'Pending', value: 'pending' },
  { labelAr: 'دفع', labelEn: 'Paid', value: 'paid' },
  { labelAr: 'ألغيت', labelEn: 'Cancelled', value: 'cancelled' },
];

export const invoiceFrequency: TSelectInvoiceFrequency[] = [
  { labelAr: 'اليومي', labelEn: 'Daily', value: 'daily' },
  { labelAr: 'أسبوعي', labelEn: 'Weekly', value: 'weekly' },
  { labelAr: 'شهريا', labelEn: 'Monthly', value: 'monthly' },
  { labelAr: 'سنوي', labelEn: 'Yearly', value: 'yearly' },
];

export const filtersLabel = {
  byCountry: {
    titleAr: 'كل البلدان',
    titleEn: 'All countries',
    code: '',
    // !we can add disabled:true to disable this option
    // disabled:true
  },
  byCity: {
    titleAr: 'جميع المدن',
    titleEn: 'All cities',
    code: '',
  },
  byRegion: {
    titleAr: 'كل المناطق',
    titleEn: 'All regions',
    code: '',
  },
  byArea: {
    titleAr: 'جميع المساحات',
    titleEn: 'All areas',
    areaFrom: '',
    areaTo: '',
    key: 'all',
  },
  byDistrict: {
    titleAr: 'كل المقاطعات',
    titleEn: 'All districts',
    code: '',
  },
  byType: {
    titleAr: 'كل الانواع',
    titleEn: 'All types',
    type: '' as TPropertyType,
  },
  byUnitStatus: {
    titleAr: 'جميع حالات الوحدة',
    titleEn: 'All unit statuses',
    code: '',
  },
  byUnitType: {
    titleAr: 'جميع أنواع الوحدات',
    titleEn: 'All unit types',
    type: 'building' as TPropertyType,
  },
  byContractStatus: {
    titleAr: 'جميع العقود',
    titleEn: 'All contracts',
    code: '',
  },
  byContractType: {
    titleAr: 'جميع أنواع العقود',
    titleEn: 'All contract types',
    code: undefined,
  },
  byProperty: {
    propertyNameAr: 'جميع العقارات',
    propertyNameEn: 'All properties',
    id: undefined,
  },
  byPortfolio: {
    name: 'All portfolios',
    id: undefined,
  },
  byInvoiceStatus: {
    labelAr: 'حسب كل الحالات',
    labelEn: 'All statuses',
  },
  byInvoiceType: {
    labelAr: 'كل الانواع',
    labelEn: 'All types',
  },
  byFrequency: {
    labelAr: 'جميع الترددات',
    labelEn: 'All frequencies',
  },
  otherDistrict: {
    titleAr: 'آخر',
    titleEn: 'Other',
    code: 'other',
  },
};

export const disabledFiltersLabel = {
  byCountry: {
    titleAr: 'حدد الدولة',
    titleEn: 'Select country',
    code: '',
    disabled: true,
  },
  byCity: {
    titleAr: 'اختر مدينة',
    titleEn: 'Select city',
    code: '',
    disabled: true,
  },
  byRegion: {
    titleAr: 'اختر المنطقة',
    titleEn: 'Select region',
    code: '',
    disabled: true,
  },
  byDistrict: {
    titleAr: 'حدد المنطقة',
    titleEn: 'Select district',
    code: '',
    disabled: true,
  },
  otherDistrict: {
    titleAr: 'آخر',
    titleEn: 'Other',
    code: 'other',
  },
};

export const selectFacility: TFacility & TDisabled = {
  id: -1,
  facilityNameEn: 'Choose facility',
  facilityNameAr: 'اختر مرفق',
  facilityDescriptionAr: '',
  facilityDescriptionEn: '',
  disabled: true,
};

export const selectFeature: TFeature & TDisabled = {
  id: -1,
  featureNameEn: 'Choose feature',
  featureNameAr: 'اختر  ميزة',
  featureDescriptionAr: '',
  featureDescriptionEn: '',
  disabled: true,
};

export const unitsFilter = {
  byUnitType: [
    {
      titleAr: 'ﻧﻮع اﻟﻮﺣﺪة',
      titleEn: 'Unit type',
      value: undefined,
    },
    {
      titleAr: 'شقة',
      titleEn: 'Apartment',
      value: 'apartment',
    },
    {
      titleAr: 'معرض',
      titleEn: 'showroom',
      value: 'showroom',
    },
  ],
  byUnitStatus: [
    {
      titleAr: 'حالة الوحدة',
      titleEn: 'Unit status',
      value: undefined,
    },
    {
      titleAr: 'مشغول',
      titleEn: 'Occupied',
      value: 'True',
    },
    {
      titleAr: 'شاغر',
      titleEn: 'Vacant',
      value: 'False',
    },
  ],
};

// prettier-ignore
export const selectTenantLabel = (locale: string): TTenant & TDisabled => ({
  email: locale === 'ar' ? 'اﺧﺘﺮ ﻣﺴﺘﺄﺟﺮ ﻣﻦ اﻟﻘﺎﺋﻤﺔ' : 'Select tenant from the list',
  id: -1,
  phone: '',
  nationalID: '',
  disabled: true,
  gender: 'male'
});

// prettier-ignore
export const selectContractLabel = (locale: string): TTenant & TDisabled => ({
  email: locale === 'ar' ? 'اﺧﺘﺮ ﻣﺴﺘﺄﺟﺮ ﻣﻦ اﻟﻘﺎﺋﻤﺔ' : 'Select tenant from the list',
  id: -1,
  phone: '',
  nationalID: '',
  disabled: true,
  gender: 'male'
});

export const selectPFrequency: TFrequencySelectOption[] = [
  {
    value: undefined,
    frequencyAr: 'يختار',
    frequencyEn: 'Select',
    disabled: true,
  },
  {
    value: 'daily',
    frequencyAr: 'اليومي',
    frequencyEn: 'Daily',
  },
  {
    value: 'weekly',
    frequencyAr: 'أسبوعي',
    frequencyEn: 'Weekly',
  },
  {
    value: 'monthly',
    frequencyAr: 'شهريا',
    frequencyEn: 'Monthly',
  },
  {
    value: 'yearly',
    frequencyAr: 'سنوي',
    frequencyEn: 'Yearly',
  },
];

export const selectPropertyLabel = {
  propertyNameEn: 'Select a property',
  propertyNameAr: 'اخترالعقار',
  id: -1,
  disabled: true,
};

export const selectBeneficiaryLabel = {
  id: -1,
  beneficiaryNameAr: 'إختر',
  beneficiaryNameEn: 'Select',
  disabled: true,
};

export const beneficiaryLabel = {
  id: -1,
  beneficiaryNameAr: 'إختر',
  beneficiaryNameEn: 'Select',
  disabled: true,
};

export const byNameLabel = {
  labelAr: 'بالاسم',
  labelEn: 'By name',
};
export const byDateLabel = {
  labelAr: 'حسب التاريخ',
  labelEn: 'By date',
};

export const byStatusItems: TSelectInvoiceStatus[] = [
  {
    labelAr: 'كل الحالات',
    labelEn: 'All statuses',
  },
  {
    labelAr: 'دفع',
    labelEn: 'Paid',
    value: 'paid',
  },
  {
    labelAr: 'قيد الانتظار',
    labelEn: 'Pending',
    value: 'pending',
  },
  {
    labelAr: 'ألغيت',
    labelEn: 'Cancelled',
    value: 'cancelled',
  },
];

export const byContractType: TSelectContractType[] = [
  {
    labelAr: 'جميع أنواع العقود',
    labelEn: 'All contract types',
  },
  {
    labelAr: 'إيجار',
    labelEn: 'Rent',
    value: 'rent',
  },
];

export const byPropertyStatusItems: TSelectPropertiesStatus[] = [
  {
    labelAr: 'كل الحالات',
    labelEn: 'All statuses',
  },
  {
    labelAr: 'شاغر',
    labelEn: 'Vacant',
    value: 'vacant',
  },
  {
    labelAr: 'محجوزة',
    labelEn: 'Occupied',
    value: 'occupied',
  },
  {
    labelAr: 'مُباع',
    labelEn: 'Sold',
    value: 'sold',
  },
];
