import FacebookIcon from '../icons/FacebookIcon';
import InstagramIcon from '../icons/InstagramIcon';
import TwitterIcon from '../icons/TwitterIcon';
import PinterestIcon from '../icons/PinterestIcon';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import Link from 'next/link';

const scMedia = [
  {
    name: 'Twitter',
    href: '#',
    icon: TwitterIcon,
  },
  {
    name: 'Instagram',
    href: '#',
    icon: InstagramIcon,
  },
  {
    name: 'Facebook',
    href: '#',
    icon: FacebookIcon,
  },
  {
    name: 'Pinterest',
    href: '#',
    icon: PinterestIcon,
  },
];

const DashboardFooter = ({
  extraWrapClassName,
}: {
  extraWrapClassName?: string;
}) => {
  const { t: footer } = useTranslation('dashboard-footer');
  const links = [
    {
      name: footer('faq'),
      href: '/faq',
      className: 'rtl:text-right ltr:text-left',
    },
    {
      name: footer('privacy-policy'),
      href: '/privacy-policy',
      className: 'rtl:text-left ltr:text-right',
    },
    {
      name: footer('terms-conditions'),
      href: '/terms-and-conditions',
      className: 'col-span-2 mt-[20px]',
    },
  ];
  return (
    <footer className="bg-white border-t-[2px] border-t-brand py-[16px] overflow-hidden layout-container-px">
      <div
        className={classNames(
          'md:flex md:items-center md:justify-between pt-[40px] md:pt-[13px] 2xl:pt-[14px]',
          extraWrapClassName
        )}
      >
        <div className="md:hidden w-full max-w-xs mx-auto">
          <nav
            className="grid mb-[40px] w-full text-center ltr:px-[30px]"
            aria-label="Footer"
          >
            {links.map((item) => (
              <div key={item.name} className={item.className}>
                <Link href={item.href}>
                  <a
                    className={
                      'text-base text-secondary text-[18px] font-medium hover:text-primary transition duration-300'
                    }
                  >
                    {item.name}
                  </a>
                </Link>
              </div>
            ))}
          </nav>
        </div>
        <div className="flex justify-center md:order-2 space-x-[30px] rtl:space-x-reverse">
          {scMedia.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-primary hover:text-brand transition duration-300"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <nav
            className="items-center justify-start whitespace-nowrap hidden md:flex"
            aria-label="Footer"
          >
            {links.map((item) => (
              <div key={item.name} className="">
                <Link href={item.href}>
                  <a
                    href={item.href}
                    className="text-base text-center text-secondary text-[18px] font-medium hover:text-primary ltr:pr-[40px] rtl:pl-[40px] transition duration-300"
                  >
                    {item.name}
                  </a>
                </Link>
              </div>
            ))}
          </nav>
          <div className="text-center md:ltr:text-left md:rtl:text-right text-sm text-secondary my-[40px] md:my-[13px] 2xl:my-[14px]">
            &copy; {new Date().getFullYear()} {footer('copyright')}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default DashboardFooter;
