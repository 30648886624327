import classNames from 'classnames';
import LoadingSpinner from '../icons/LoadingSpinner';

type Props = {
  label: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  color?: 'primary' | 'secondary' | 'cancel';
  link?: boolean;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  table?: boolean;
  onHoverText?: string
};

// prettier-ignore
const baseFont = classNames(
  'text-[16px] ltr:leading-[20px] rtl:leading-[18px]',
  'md:text-[20px] md:ltr:leading-[24px] md:rtl:leading-[22px]',
);

const baseFontTable = 'text-[14px] leading-[17px]';
// prettier-ignore
const baseStyle = classNames(
  'overflow-hidden relative inline-flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed border border-transparent rounded-[4px] focus:outline-none',
);

const Button = (props: Props) => {
  // TODO("Make the component render <a> instead of <button> for links")
  const color = () => {
    // TODO("Refactor)
    if (props.link === true) {
      // prettier-ignore
      const baseStyle = classNames(
        'underline bg-transparent font-normal px-0 py-0 ring-0 focus:ring-0 focus:ring-offset-0',
      );
      if (props.color == 'secondary')
        return classNames(
          baseStyle,
          'text-secondary ring-0 focus:ring-0 focus:ring-offset-0'
        );
      if (props.color == 'cancel')
        return classNames(
          baseStyle,
          'text-cancel ring-0 focus:ring-0 focus:ring-offset-0'
        );
      return classNames(
        baseStyle,
        'text-primary ring-0 focus:ring-0 focus:ring-offset-0'
      );
    }

    const baseStyle = classNames(
      'py-[6px] focus:ring-1 focus:ring-offset-1',
      props.table ? 'px-[15px]' : 'px-[20px] md:px-[40px]'
    );
    if (props.color == 'secondary')
      return classNames(
        baseStyle,
        'bg-action-primary text-white focus:bg-action-primary focus:ring-action-primary'
      );
    if (props.color == 'cancel')
      return classNames(
        baseStyle,
        'bg-cancel text-white focus:bg-cancel focus:ring-cancel'
      );
    return classNames(
      baseStyle,
      'bg-primary text-white active:ring-primary active:bg-primary focus:bg-primary focus:ring-primary'
    );
  };

  const onClick = () => {
    props.onClick?.();
  };

  return (
    <button
      type={props.type || 'button'}
      onClick={onClick}
      className={classNames(
        baseStyle,
        props.link ? 'ltr:font-500 rtl:font-400' : 'ltr:font-600 rtl:font-700',
        'hover:bg-opacity-80 whitespace-nowrap',
        color(),
        props.className || (props.table ? baseFontTable : baseFont),
        props.table ? 'h-[30px]' : 'h-[38px] md:h-[44px]'
      )}
      disabled={props.disabled || props.loading || props.label.length === 0}
      aria-label={props.label.replace(/\s+/g, '-')}
      title={props.onHoverText ? props.onHoverText : props.label}
    >
      <span className={classNames(props.loading ? 'invisible' : 'visible')}>
        {props.label || '-'}
      </span>
      {props.loading && <span className="sr-only">{props.label || '-'}</span>}
      {props.loading && <LoadingSpinner />}
    </button>
  );
};

export default Button;
