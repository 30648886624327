import { TIconProps } from '../../types/app';

const InstagramIcon = (props: TIconProps) => {
  return (
    <svg
      className={props.className || ''}
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.16c3.206 0 3.586.015 4.847.071 1.172.052 1.805.249 2.226.413a3.71 3.71 0 011.379.895c.421.422.68.82.895 1.378.164.422.36 1.06.412 2.227.057 1.265.07 1.645.07 4.847 0 3.206-.013 3.586-.07 4.846-.051 1.172-.248 1.805-.412 2.227-.216.558-.478.961-.895 1.378a3.69 3.69 0 01-1.379.895c-.421.165-1.059.361-2.226.413-1.266.056-1.645.07-4.847.07-3.206 0-3.586-.014-4.847-.07-1.172-.052-1.805-.248-2.226-.413a3.71 3.71 0 01-1.379-.895 3.691 3.691 0 01-.895-1.378c-.164-.422-.36-1.06-.412-2.227-.057-1.265-.07-1.645-.07-4.846 0-3.207.013-3.586.07-4.847.051-1.172.248-1.805.412-2.227.216-.558.478-.96.895-1.378.422-.422.82-.68 1.379-.895.421-.164 1.059-.361 2.226-.413 1.261-.056 1.64-.07 4.847-.07zM12 0C8.742 0 8.334.014 7.055.07 5.78.127 4.903.333 4.144.628a5.857 5.857 0 00-2.128 1.388A5.88 5.88 0 00.628 4.139C.333 4.903.127 5.775.07 7.05.014 8.334 0 8.742 0 12s.014 3.666.07 4.945c.057 1.275.263 2.152.558 2.911.31.792.717 1.463 1.388 2.128a5.866 5.866 0 002.123 1.383c.764.296 1.636.502 2.911.558 1.28.056 1.688.07 4.945.07 3.258 0 3.666-.014 4.946-.07 1.275-.056 2.151-.262 2.91-.558a5.866 5.866 0 002.124-1.383 5.866 5.866 0 001.383-2.123c.295-.764.501-1.636.558-2.911.056-1.28.07-1.688.07-4.945 0-3.258-.014-3.666-.07-4.946-.057-1.275-.263-2.151-.558-2.91a5.62 5.62 0 00-1.374-2.133A5.866 5.866 0 0019.861.633C19.097.338 18.225.13 16.95.075 15.666.015 15.258 0 12 0z"
        fill="currentColor"
      />
      <path
        d="M12 5.836A6.166 6.166 0 005.834 12 6.166 6.166 0 0012 18.164 6.166 6.166 0 0018.165 12a6.166 6.166 0 00-6.165-6.164zm0 10.162A4 4 0 1112 8a4 4 0 010 7.998zM19.847 5.592a1.44 1.44 0 11-2.879 0 1.44 1.44 0 012.879 0z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InstagramIcon;
